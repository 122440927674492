<template>
  <v-row>
    <!-- kitchen sink -->
    <v-col cols="12">
      <v-card>
        <v-card-title>{{ `${$t('menu.configuration')} / ${$t('permiso.notifications')} / Bot Telegram` }}</v-card-title>
        <v-card-text>
          <BotTelegram />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import BotTelegram from './form/BotTelegram.vue'

export default {
  components: {
    BotTelegram,
  },
}
</script>
